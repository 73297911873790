<template>
    <Card>
        <div slot="title">{{ title }}</div>
        <div class="form_item">
            <div class="form_label align_self_start">反馈内容</div>
            <div class="break_all">{{ formData.note }}</div>
        </div>
        <div class="form_item" v-if="!!formData.note_img">
            <div class="form_label align_self_start">反馈图片</div>
            <div class="relative width_100_percent flex flex_wrap pic_container">
                <previewImage v-for="(src, idx) in (formData.note_img || '').split(',') || []" :key="encodeURIComponent(src + idx)" :thumWidth="60" :thumHeight="60" :src="src"></previewImage>
            </div>
        </div>
        <div class="mt_50 flex align_center justify_center form_item">
            <i-button type="primary" ghost @click="onBack">返回</i-button>
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </Card>
</template>
<script>
import previewImage from '@/components/previewImage';

export default {
    name: 'goodParamsAdd',
    components: {
        previewImage,
    },
    data() {
        return {
            title: '反馈详情',
            formData: {
                id: null,
                note: null,
                note_img: null,
            },
            isEdit: false,
        };
    },
    mounted() {
        this.routeParamsInjectFormData();
    },
    watch: {
        //
    },
    methods: {
        //获取详情
        getDetail() {
            if (this.isEdit) {
                this.showAjaxLoading();
            }
        },
        //返回
        onBack() {
            this.$router.back();
        },
    },
};
</script>
<style lang="less" scoped>
.pic_container {
    gap: 10px;
    .pic {
        width: 200px;
        height: 200px;
        object-fit: contain;
    }
}
</style>
